import React from 'react';
import clsx from 'clsx';

import useStyles from './Style';

export function Main (props) { 
    const classes = useStyles();

    return (
        <main
            className={clsx(classes.main, classes.content, {
                [classes.contentShift]: props.open,
            })}
        >
            { props.children }
        </main>  
    );
}

export default Main;