import React from 'react';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Grid from '@material-ui/core/Grid';

import Title from '../../components/Title/Title';

import useStyles from './Style';

const Anexo1 = () => {
    const classes = useStyles();
    return (
        <>
            <Title className={classes.title}>
               ANEXO I
            </Title>

            <Grid 
                container 
                align='center' 
                direction="row" 
                justifyContent="center"
                alignItems="center"
            >
                <Grid item >
                    <TableContainer component="div">
                        <Table className={classes.table} size="small" aria-label="a dense table">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="center" colSpan={2} className={classes.purple}><b>Calendário Oficial da 1ª Olimpíada Mirim - OBMEP</b></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                <TableRow>
                                    <TableCell align="center" width='35%' component="th" scope="row" className={classes.blue}><b>02 DE MAIO a 16 DE JUNHO</b></TableCell>
                                    <TableCell align="center">Período para inscrições das escolas na 1a Olimpíada Mirim - OBMEP</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center" width='35%' component="th" scope="row" className={classes.blue}><b>01 DE AGOSTO a 20 DE AGOSTO</b></TableCell>
                                    <TableCell align="center">Disponibilização do material de provas</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center" width='35%' component="th" scope="row" className={classes.blue}><b>30 DE AGOSTO</b></TableCell>
                                    <TableCell align="center" className={classes.orange}>PROVA DA 1ª FASE</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center" width='35%' component="th" scope="row" className={classes.blue}><b>31 DE AGOSTO a 12 DE SETEMBRO </b></TableCell>
                                    <TableCell align="center">Período para a correção das provas da 1a Fase pela escola</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center" width='35%' component="th" scope="row" className={classes.blue}><b>31 DE AGOSTO a 13 DE SETEMBRO </b></TableCell>
                                    <TableCell align="center">
                                        Período para as escolas informarem as notas dos alunos classificados para a 2a Fase (inclusive a nota) e as necessidades especiais (prova braille ou ampliada, se houver) - 
                                        exclusivamente na área restrita da escola, no site da Olimpíada Mirim - OBMEP (olimpiadamirim.obmep.com.br)
                                    </TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center" width='35%' component="th" scope="row" className={classes.blue}><b>11 DE OUTUBRO</b></TableCell>
                                    <TableCell align="center" className={classes.orange}>PROVA DA 2a FASE</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center" width='35%' component="th" scope="row" className={classes.blue}><b>12 DE OUTUBRO a 18 DE OUTUBRO </b></TableCell>
                                    <TableCell align="center">Período para correção das provas da 2a Fase pela escola</TableCell>
                                </TableRow>
                                <TableRow>
                                    <TableCell align="center" width='35%' component="th" scope="row" className={classes.blue}><b>12 DE OUTUBRO a 27 DE OUTUBRO </b></TableCell>
                                    <TableCell align="center">
                                        Período para as escolas informarem as notas das provas da 2a fase - exclusivamente na área restrita da escola, no site da Olimpíada Mirim - 
                                        OBMEP (olimpiadamirim.obmep.org.br)
                                    </TableCell>
                                </TableRow>
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
            </Grid>
        </>
    )
}

export default Anexo1;