import React, { useState, createContext } from 'react';
import AuthorizedFunction from '../security/AuthorizedFunction';
import { __ESCOLA_DASHBOARD } from '../security/RoleConfiguration';

export const MenuContext = createContext([AuthorizedFunction([__ESCOLA_DASHBOARD]) ? true : false, () => { }]);

export default function MenuProvider({ children }) {

    const [open, setOpen] = useState();

    return (
        <MenuContext.Provider value={{ open, setOpen }}>
            {children}
        </MenuContext.Provider>
    );
}