import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { blue, green, orange, red } from '@material-ui/core/colors';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import DoneIcon from '@material-ui/icons/Done';
import BlockIcon from '@material-ui/icons/Block';
import LoopIcon from '@material-ui/icons/Loop';
import ErrorIcon from '@material-ui/icons/Error';
import ScheduleOutlinedIcon from '@material-ui/icons/ScheduleOutlined';

const useStyles = makeStyles((theme) => (
    {
        info: {
            backgroundColor: blue[400],
            color: "#fff",
        },
        success: {
            backgroundColor: green[400],
            color: "#fff",
        },
        warning: {
            backgroundColor: orange[400],
            color: "#fff",
        },
        error: {
            backgroundColor: red[400],
            color: "#fff",
        },
        icon: {
            color: "#fff",
        }
    }
));

const ChipStatus = ({ status }) => {
    const classes = useStyles();

    return (
        <Chip
            size="small"
            icon={status === 'FINALIZADA' ? <DoneIcon className={classes.icon} /> : status === 'ERRO' ? <ErrorIcon className={classes.icon} /> : status === 'INTERROMPIDA' ? <BlockIcon className={classes.icon} /> : <LoopIcon className={classes.icon} />}
            label={<b>{status === 'FINALIZADA' ? 'Finalizada' : status === "ERRO" ? "Erro" : status === 'INTERROMPIDA' ? 'Interrompida' : 'Em Andamento'}</b>}
            className={status === 'FINALIZADA' ? classes.success : status === 'ERRO' ? classes.error : status === 'INTERROMPIDA' ? classes.warning : classes.info}
        />
    )
}

const ChipTipo = ({ tipo }) => {
    const classes = useStyles();

    return (
        <Chip
            size="small"
            icon={tipo === 'MANUAL' ? <PlayCircleOutlineIcon className={classes.icon} /> : <ScheduleOutlinedIcon className={classes.icon} />}
            label={<b>{tipo === 'MANUAL' ? 'Manual' : 'Agendada'}</b>}
            className={tipo === 'MANUAL' ? classes.info : classes.success}
        />
    )
}

const InformacoesUltimaExecucao = ({ inicio, termino, usuario, status, tipo, paraProcessar, processados }) => {
    return (
        <>
            <Grid container spacing={2} style={{ paddingBottom: 6 }} >
                <Grid item sm={6} lg={2}>
                    <Typography variant="body2"><b>Relatório da Última Execução:</b></Typography>
                </Grid>
            </Grid>

            <Grid container spacing={2} style={{ paddingTop: 2 }}>
                <Grid item sm={6} lg={2}>
                    <Typography variant="body2"><b>Início:</b> {inicio}</Typography>
                </Grid>

                { tipo === "MANUAL" &&
                    <Grid item sm={6} lg={2}>
                        <Typography variant="body2"><b>Usuário que Iniciou:</b> {usuario}</Typography>
                    </Grid>
                }

                { termino &&
                    <Grid item sm={6} lg={2}>
                        <Typography variant="body2"><b>Término:</b> {termino}</Typography>
                    </Grid>
                }

                <Grid item sm={6} lg={2}>
                    <Typography variant="body2" style={{ display: 'inline' }}><b>Status de Processamento:</b></Typography> <ChipStatus status={status} />
                </Grid>

                <Grid item sm={6} lg={2}>
                    <Typography variant="body2" style={{ display: 'inline' }}><b>Tipo de Tarefa:</b></Typography> <ChipTipo tipo={tipo} />
                </Grid>

                { status !== "FINALIZADA" &&
                    <Grid item sm={6} lg={2}>
                        <Typography variant="body2"><b>Total de Registros para Processar:</b> {paraProcessar ?? "Calculando..."}</Typography>
                    </Grid>
                }

                <Grid item sm={6} lg={2}>
                    <Typography variant="body2"><b>Total de Registros Processados:</b> {processados ?? 0}</Typography>
                </Grid>
            </Grid>
        </>
    )
}

export default InformacoesUltimaExecucao;