import React, { useState } from 'react';

import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

export const CheckBox = props => {
    const [checked, setChecked] = useState(props.checked);

    const handleChange = e => { 
        props.onChange(e);
        setChecked(e.target.checked) 
    };

    return (
        <FormControlLabel
            control={
                <Checkbox
                    {...props}
                    checked={checked}
                    onChange={handleChange}
                    color="primary"
                />
            }
            label={props.label}
        />
    );
}

export default CheckBox;
