import React, { Component } from 'react';

import Regua from '../../components/Regua/Regua';

class LayoutPublic extends Component {
  render() {
    return (
      <>
        <div>
          {this.props.children}
        </div>
        <Regua open={false} />
      </>
    );
  }
}

export default LayoutPublic;