import React from 'react';
import ReactDOM from 'react-dom';

import { KeycloakProvider } from '@react-keycloak/web';
import keycloak from './security/keycloak';

import { Provider } from 'react-redux';
import store from './store';

import Routes from './routes/Routes';

import './assets/sass/style.scss';

ReactDOM.render( 
  <KeycloakProvider keycloak={keycloak} initConfig={{ checkLoginIframe: false }} >
    <Provider store={store} >
      <Routes />
    </Provider>
  </KeycloakProvider>,
  document.getElementById('root')
);