import React from 'react';
import clsx from 'clsx';

import { makeStyles } from '@material-ui/core/styles';

import Fab from '@material-ui/core/Fab';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';
import GetAppIcon from '@material-ui/icons/GetApp';
import { ButtonPrimary } from '../Button/Button';

const useStyles = makeStyles(
    (theme) => (
        {
            btnDownload: {
                zIndex: '2',
                position: 'fixed',
                align: 'left',
                bottom: theme.spacing(16),
                right: theme.spacing(6),
            },
            btnDownloadMobile: {
                display: 'none',
                ['@media (max-width:780px)']: { // eslint-disable-line no-useless-computed-key
                    display: 'inline-flex'
                },
            },
            btnDownloadNormal: {
                display: 'inline-flex',
                ['@media (max-width:780px)']: { // eslint-disable-line no-useless-computed-key
                    display: 'none',
                },
            }
        }
    )
);

export const Download = (props) => {
    const classes = useStyles();

    const handleClick = () => window.open(props.file, '_blank');

    return (
        <>
            <div onClick={handleClick} className={clsx(classes.btnDownload, classes.btnDownloadMobile)}>
                <Tooltip title={`Fazer Download do ${props.type}`} TransitionComponent={Zoom}> 
                    <Fab color="primary" size="small" aria-label="Ir para o Topo">
                        <GetAppIcon />
                    </Fab>
                </Tooltip>
            </div>
            <div onClick={handleClick} className={clsx(classes.btnDownload, classes.btnDownloadNormal)}>
                <ButtonPrimary 
                    color="primary"
                    aria-label={`Fazer Download do ${props.type}`}
                    endIcon={<GetAppIcon />}
                    name={`Download`}
                />
            </div>
        </>
    )
}

export default useStyles;