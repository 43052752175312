import React from 'react';

import Grid from '@material-ui/core/Grid';
import DescriptionIcon from '@material-ui/icons/Description';

import { ButtonPrimary } from '../../components/Button/Button';

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles( theme => ({
  body: {
    margin: theme.spacing(3, 0),
  },
}));

export const ButtonExport = props => {
    const classes = useStyles();
    return (
      <Grid item sm={12} lg={2} className={classes.body}>
        <ButtonPrimary
          startIcon={<DescriptionIcon />}
          name={props.title}
          {...props}
        />
      </Grid>
    )
}

export default ButtonExport;