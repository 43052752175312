import React from 'react';

import Typography from '@material-ui/core/Typography';

import Title from '../../components/Title/Title';

import useStyles from './Style';

const Inscricao = () => {
    const classes = useStyles();
    return (
        <>
            <Title className={classes.title}>
               3. DA INSCRIÇÃO
            </Title>

            <Typography className={classes.text} variant="body2">
                <b>3.1</b> A inscrição é gratuita.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <b>3.2</b> A escola, antes de realizar a sua inscrição, deverá ler atentamente este regulamento e seus anexos, para se certificar de que aceita todas as condições nele estabelecidas e de que 
                preenche todos os requisitos exigidos  para a participação na 1ª Olimpíada Mirim - OBMEP.
            </Typography>
            
            <Typography className={classes.text} variant="body2">
                <b>3.3</b> As inscrições deverão ser efetuadas dentro do prazo estipulado no Calendário Oficial da 1ª Olimpíada Mirim - OBMEP (Anexo I), por meio do preenchimento da Ficha de Inscrição disponível 
                exclusivamente no site www.olimpiadamirim.obmep.org.br
            </Typography>

            <Typography className={classes.text} variant="body2">
                <b>3.4</b> As inscrições para participação dos(as) alunos(as) na 1ª Olimpíada Mirim - OBMEP deverão ser realizadas exclusivamente pelas escolas ou secretarias de educação.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <b>3.5 A ESCOLA OU SECRETARIA DE EDUCAÇÃO MUNICIPAL OU ESTADUAL, AO REALIZAR A INSCRIÇÃO, CONCORDA INTEGRALMENTE COM TODAS AS CONDIÇÕES E REGRAS PREVISTAS NO PRESENTE REGULAMENTO, 
                NÃO PODENDO ALEGAR DESCONHECIMENTO POSTERIOR, BEM COMO COMPROMETE-SE A DAR PUBLICIDADE AO PRESENTE REGULAMENTO, NA FORMA DO ITEM 3.13.</b>
            </Typography>

            <Typography className={classes.text} variant="body2">
                <b>3.6</b> Para se inscrever na 1ª Olimpíada Mirim - OBMEP, a escola deverá informar obrigatoriamente o respectivo código MEC/INEP, o qual será considerado como o número de identificação 
                na Olimpíada Mirim em todo o processo e preencher todos os campos.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <b>3.7</b> O acesso à área restrita pelas escolas inscritas deve ser realizado por meio do site da Olimpíada Mirim - OBMEP (www.olimpiadamirim.obmep.org.br).
            </Typography>

            <Typography className={classes.text} variant="body2">
                <b>3.8</b> O login da escola ou da secretaria, para acesso ao sistema, será sempre o código MEC/INEP (de cada escola), e a senha será aquela cadastrada pela escola/secretaria no ato do processo de 
                inscrição. Essas informações deverão ser utilizadas para acesso ao sistema em todas as etapas da Olimpíada Mirim - OBMEP.

                <ul className={classes.list}>
                    <li><b>3.8.1</b> Não serão aceitas inscrições enviadas por meio de mensagens eletrônicas, telefone ou por meio postal;</li>
                    <li><b>3.8.2</b> Não serão aceitas inscrições incompletas. A escola é responsável por informar corretamente todos os dados no ato da inscrição, especialmente seu endereço e contatos, inclusive 
                    e-mail e números de telefone. O IMPA não se responsabilizará por qualquer problema na participação das escolas decorrente de informações equivocadas ou incompletas, como em caso de impossibilidade 
                    de entrega do material de provas em razão de endereços errados ou incompletos;</li>
                    <li><b>3.8.3</b> Não serão aceitas inscrições fora do prazo.</li>
                </ul>
            </Typography>

            <Typography className={classes.text} variant="body2">
                <b>3.9</b> O IMPA não se responsabilizará por inscrições não realizadas por motivos de ordem técnica dos computadores, falhas de comunicação, erro, falta de energia, bem como outros fatores que 
                impossibilitem a transferência de dados. Poderão ser inscritas apenas as escolas públicas que atendam a alunos do 2º, 3º, 4º e 5º anos do ensino fundamental. 
            </Typography>

            <Typography className={classes.text} variant="body2">
                <b>3.10</b> O responsável pela inscrição deverá informar corretamente todos os dados solicitados na Ficha de Inscrição.
            </Typography>

            <Typography className={classes.text} variant="body2">
                <b>3.11</b> O responsável pela inscrição deverá informar, na Ficha de Inscrição, apenas o total de alunos(as) participantes por nível, não sendo necessária a inscrição nominal de alunos(as).
            </Typography>

            <Typography className={classes.text} variant="body2">
                <b>3.12</b> É de responsabilidade do representante de escola ou da secretaria a conservação do recibo gerado ao final do processo de inscrição, o qual constitui comprovante de inscrição da(s) 
                escola(s) sob sua responsabilidade.

                <ul className={classes.list}>
                    <li><b>3.12.1</b> Caso o recibo não seja gerado, o responsável pela inscrição deverá entrar em contato com a central de atendimento da Olimpíada Mirim - OBMEP pelo telefone (21) 2529-5084, em 
                    horário comercial (horário de Brasília), ou por meio de mensagem para olimpiadamirim@obmep.org.br.</li>
                </ul>
            </Typography>

            <Typography className={classes.text} variant="body2">
                <b>3.13</b> É de responsabilidade de cada escola ou secretaria divulgar amplamente a participação na   1ª Olimpíada Mirim - OBMEP e, também, o teor deste Regulamento e o material de apoio, 
                ambos disponíveis para consulta no site www.olimpiadamirim.obmep.org.br, para os seus alunos e seus respectivos representantes legais, além de professores e demais profissionais da instituição.
            </Typography>
        </>
    )
}

export default Inscricao;