import API from './API';

const path = `ufs`;

const UFService = {
    // LISTAR TODOS
    list: async () => await API.list(path),

    validarINEP: async (id) => await API.list(`${path}/validar-mec/${id}`),
}

export default UFService;