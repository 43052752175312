import React from 'react';

import CachedIcon from '@material-ui/icons/Cached';
import Chip from '@material-ui/core/Chip';
import Tooltip from '@material-ui/core/Tooltip';
import Zoom from '@material-ui/core/Zoom';

export const Cache = ({ ultimaAtualizacao, action }) => {
    return (
        <Chip
            style={{ marginLeft: 12 }}
            label={`Última Atualização: ${ultimaAtualizacao}`}
            deleteIcon={<Tooltip title={"Atualizar Agora"} TransitionComponent={Zoom}><CachedIcon /></Tooltip>}
            onDelete={action}
        />
    )
}

export default Cache;