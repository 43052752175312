import Form from '../../views/Arquivo/Form';
import { __ARQUIVO_ADICIONAR, __ARQUIVO_EDITAR } from '../../security/RoleConfiguration';

export const path = '/admin/arquivo';

export const listRoutes = [
    {
        path: path + '/adicionar',
        component: Form,
        roles: [ __ARQUIVO_ADICIONAR ],
    },
    {
        path: path + '/:id/editar',
        component: Form,
        roles: [ __ARQUIVO_EDITAR ],
    }
];

export default listRoutes;