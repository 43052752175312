import React from 'react';
import { Link } from 'react-router-dom';

import Grid from '@material-ui/core/Grid';
import AddIcon from '@material-ui/icons/Add';

import { ButtonPrimary } from '../../components/Button/Button';

import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles( theme => ({
  body: {
    margin: theme.spacing(3, 0),
  },
}));

export const ButtonAdd = props  => {
    const classes = useStyles();
    return (
        <Grid item sm={12} lg={2} className={classes.body}>
            <ButtonPrimary
                startIcon={<AddIcon />}
                to={props.to}
                component={Link}
                name={props.title}
            />
        </Grid>
    )
}

export default ButtonAdd;