import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';

import Table, { DataTableLineTotals as Totals } from '../../components/Table/Table';

export class DadosProvasEspeciais extends Component {
    constructor (props) {
        super(props)
        props.parentRef(this);
        this.state = {
            schools: [],
        }
    }

    // SOMA TOTAL DE ESCOLAS
    sumEscolasParticipantes = () => this.state.schools.length;

    sumAlunosDeficienciaParcialMirim1 = () => this.state.schools.reduce((total, sch) => total + sch.qtdAlunosDeficienciaParcialMirim1, 0);
    sumAlunosDeficienciaTotalMirim1 = () => this.state.schools.reduce((total, sch) => total + sch.qtdAlunosDeficienciaTotalMirim1, 0);
    sumAlunosDeficienciaParcialMirim2 = () => this.state.schools.reduce((total, sch) => total + sch.qtdAlunosDeficienciaParcialMirim2, 0);
    sumAlunosDeficienciaTotalMirim2 = () => this.state.schools.reduce((total, sch) => total + sch.qtdAlunosDeficienciaTotalMirim2, 0);
    
    render () {
        const columns = [
            { label: 'UF', name: 'nmUf', func: null, key: false, width: '5', },
            { label: 'Região', name: 'cdRegiao', func: null, key: false, width: '5', },
            { label: 'Municipio', name: 'nmMunicipio', func: null, key: false, width: '10', },
            { label: 'Dep. Adm. ', name: 'nmDependenciaAdministrativa', func: null, key: false, width: '5', },
            { label: 'INEP', name: 'cdMecEscola', func: null, key: true, width: '5', },
            { label: 'Escola', name: 'nmEscola', func: null, key: false, width: '12', },
            { label: 'Mirim 1 - Provas Ampliadas', name: 'qtdAlunosDeficienciaParcialMirim1', func: null, key: false, width: '5', },
            { label: 'Mirim 1 - Provas Braile', name: 'qtdAlunosDeficienciaTotalMirim1', func: null, key: false, width: '7', },
            { label: 'Mirim 2 - Provas Ampliadas', name: 'qtdAlunosDeficienciaParcialMirim2', func: null, key: false, width: '5', },
            { label: 'Mirim 2 - Provas Braile', name: 'qtdAlunosDeficienciaTotalMirim2', func: null, key: false, width: '8', },
        ];
        
        const actions = [];

        const totals = [
            { column: 0, label: 'Totais', value: "#", width: '40', },
            { column: 1, label: 'Escolas', value: this.sumEscolasParticipantes(), width: '12', },
            { column: 2, label: 'Mirim 1 - Provas Ampliadas', value: this.sumAlunosDeficienciaParcialMirim1(), width: '12', },
            { column: 3, label: 'Mirim 1 - Provas Braile', value: this.sumAlunosDeficienciaTotalMirim1(), width: '12', },
            { column: 5, label: 'Mirim 2 - Provas Ampliadas', value: this.sumAlunosDeficienciaParcialMirim2(), width: '12', },
            { column: 6, label: 'Mirim 2 - Provas Braile', value: this.sumAlunosDeficienciaTotalMirim2(), width: '12', },
        ];

        return (
            <Grid container spacing={3} style={{ marginTop: this.props.marginTop ?? 8 }}>
                <Grid item xs={12}>
                    <Table              
                        columns={columns}
                        tableData={this.state.schools}
                        actions={actions}
                        page={null}
                        rowsPerPage={null}
                        sort={null}
                        fixedHeader={true}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Totals lineData={totals} columns={columns} showHead={true} />
                </Grid>
            </Grid>
        )
    }
}

export default DadosProvasEspeciais;