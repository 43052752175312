import Form from '../../views/Aluno/Form';
import { 
    __ALUNO_ADICIONAR,
    __ALUNO_EDITAR,
} from '../../security/RoleConfiguration';

export const path = '/admin';

export const listRoutes = [
    {
        path: path + '/:inep/aluno/adicionar',
        component: Form,
        roles: [ __ALUNO_ADICIONAR ],
    },
    {
        path: path + '/:inep/aluno/:id/editar',
        component: Form,
        roles: [ __ALUNO_EDITAR ],
    },
];

export default listRoutes;