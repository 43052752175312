import Form from '../../views/Email/Form';
import { __EMAIL_ENVIAR } from '../../security/RoleConfiguration';

export const path = '/admin/emails';

export const listRoutes = [
    {
        path: path + '/:id',
        component: Form,
        roles: [ __EMAIL_ENVIAR ],
    }
];

export default listRoutes;