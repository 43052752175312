import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';

import Text from '../../components/Inputs/Text/Text';

import { mecCodeMask } from '../../helper/MaskHelper';

export class EscolaFilterFields extends Component {
    constructor(props) {
        super(props);
        props.parentRef(this);
        this.state = {
            filters: {
              nameSchool: '',
              emailSchool: '',
              inepSchool: '',         
            }
        }
    }
  
    handleChange = e => {
      this.setState({
          filters: {
              ...this.state.filters,  
              [e.target.name]: e.target.name === 'inepSchool' ? mecCodeMask(e.target.value) : e.target.value 
          }
      });
    };
  
    render () {
        return (
            <>
              <Grid item sm={12} lg={2}>
                <Text
                  maxLength='8'
                  label="INEP"
                  name='inepSchool'
                  value={this.state.filters.inepSchool}
                  onChange={this.handleChange}
                  disabled={this.props.disabledChangeAll}
                />
              </Grid>
              {!this.props.disabledNameSchool && 
                <Grid item sm={12} lg={3}>
                  <Text
                    label='Nome da Escola'
                    value={this.state.filters.nameSchool}
                    name='nameSchool'
                    onChange={this.handleChange}
                    disabled={this.props.disabledChangeAll}
                  />
                </Grid>
              }
              {!this.props.disabledEmail &&
                <Grid item sm={12} lg={3}>
                  <Text
                    label='Email da Escola'
                    value={this.state.filters.emailSchool}
                    name='emailSchool'
                    onChange={this.handleChange}
                    disabled={this.props.disabledChangeAll}
                  />
                </Grid> 
              }
            </>
        )
    }
  }
  
  export default EscolaFilterFields;