import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';

import Text from '../../components/Inputs/Text/Text';
import CheckBox from '../../components/Inputs/CheckBox/CheckBox';

import {
    isBlank,
    isBlankHelperText,
    isPasswordsDiffHelperText,
} from '../../helper/ValidationHelper';

import AuthorizedFunction from '../../security/AuthorizedFunction';
import { __ADMINISTRADOR, __CENTRAL } from '../../security/RoleConfiguration';

export class Password extends Component {
    constructor(props) {
        super(props);
        props.parentRef(this);
        this.state = {
            password: '',
            passwordRepeat: '',
            passwordTemporario: false,
            errors: {
                password: false,
                passwordRepeat: false,
            },
            helpers: {
                password: null,
                passwordRepeat: null,
            },
        }
        this.isValid = this.isValid.bind(this);
    }

    // INPUTS
    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.name === 'passwordTemporario' ? e.target.checked : e.target.value,
            errors: { ...this.state.errors, [e.target.name]: false },
            helpers: { ...this.state.helpers, [e.target.name]: null }
        })
    }

    // VALIDAÇÕES
    isValid = () => {
        const isDiffPasswords = () => this.state.password !== this.state.passwordRepeat;

        if (isBlank(this.state.password) || isBlank(this.state.passwordRepeat) || isDiffPasswords()) {
            this.setState({
                errors: {
                    password: isBlank(this.state.password) ? true : isDiffPasswords() ? true : false,
                    passwordRepeat: isBlank(this.state.passwordRepeat) ? true : isDiffPasswords() ? true : false,
                },
                helpers: {
                    password: isBlank(this.state.password) ? isBlankHelperText() : null,
                    passwordRepeat: isBlank(this.state.passwordRepeat) ? isBlankHelperText() : isDiffPasswords() ? isPasswordsDiffHelperText() : null,
                },
            });
            return false;
        }
        return true;
    }

    render() {
        return (
            <Grid container spacing={3} alignItems='center'>
                <Grid item sm={this.props.fullWidth ? 12 : 4} lg={this.props.fullWidth ? 12 : 4}>
                    <Text
                        required
                        type="password"
                        name="password"
                        label="Nova Senha"
                        value={this.state.password}
                        onChange={this.handleChange}
                        error={this.state.errors.password}
                        helperText={this.state.helpers.password}
                    />
                </Grid>
                <Grid item sm={this.props.fullWidth ? 12 : 4} lg={this.props.fullWidth ? 12 : 4}>
                    <Text
                        required
                        type="password"
                        name="passwordRepeat"
                        label="Repetir Nova Senha"
                        value={this.state.passwordRepeat}
                        onChange={this.handleChange}
                        error={this.state.errors.passwordRepeat}
                        helperText={this.state.helpers.passwordRepeat}
                    />
                </Grid>
                {(AuthorizedFunction([__ADMINISTRADOR]) || AuthorizedFunction([__CENTRAL])) &&
                    <Grid item sm={this.props.fullWidth ? 12 : 4} lg={this.props.fullWidth ? 12 : 4}>
                        <CheckBox
                            name="passwordTemporario"
                            label="Usuário deverá trocar de senha no próximo login"
                            checked={this.state.passwordTemporario}
                            onChange={this.handleChange}
                        />
                    </Grid>
                }
            </Grid>
        )
    }
}

export default Password;