import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';

import Text from '../../components/Inputs/Text/Text';

import { isBlank, isBlankHelperText } from '../../helper/ValidationHelper';
import { numberMask } from '../../helper/MaskHelper';
import AlertaAlunosPrevistos from './AlertaAlunosPrevistos';

export class Fase1Participantes extends Component {
    constructor(props) {
        super(props);
        props.parentRef(this);
        this.state = {
            inscricaoMirim1QtdAlunosPrevistos: '',
            inscricaoMirim2QtdAlunosPrevistos: '',
            inscricaoMirim1QtdAlunosParticipantes: '',
            inscricaoMirim2QtdAlunosParticipantes: '',
            errors: {
                inscricaoMirim1QtdAlunosParticipantes: false,
                inscricaoMirim2QtdAlunosParticipantes: false,
            },
            helpers: {
                inscricaoMirim1QtdAlunosParticipantes: null,
                inscricaoMirim2QtdAlunosParticipantes: null,
            },
        }
        this.isValid = this.isValid.bind(this);
    }

    // INPUTS
    handleChange = e => {
        this.setState({
            [e.target.name]: numberMask(e.target.value),
            errors: {
                inscricaoMirim1QtdAlunosParticipantes: false,
                inscricaoMirim2QtdAlunosParticipantes: false,
            },
            helpers: {
                inscricaoMirim1QtdAlunosParticipantes: null,
                inscricaoMirim2QtdAlunosParticipantes: null,
            },
        })
    }

    // VALIDAÇÕES
    isValid = () => {
        if (isBlank(this.state.inscricaoMirim1QtdAlunosParticipantes) || isBlank(this.state.inscricaoMirim2QtdAlunosParticipantes)) {
            this.setState({
                errors: {
                    inscricaoMirim1QtdAlunosParticipantes: isBlank(this.state.inscricaoMirim1QtdAlunosParticipantes) ? true : false,
                    inscricaoMirim2QtdAlunosParticipantes: isBlank(this.state.inscricaoMirim2QtdAlunosParticipantes) ? true : false,
                },
                helpers: {
                    inscricaoMirim1QtdAlunosParticipantes: isBlank(this.state.inscricaoMirim1QtdAlunosParticipantes) ? isBlankHelperText() : null,
                    inscricaoMirim2QtdAlunosParticipantes: isBlank(this.state.inscricaoMirim2QtdAlunosParticipantes) ? isBlankHelperText() : null,
                },
            });
            return false;
        }
        return true;
    }

    render() {
        return (
            <>
                <AlertaAlunosPrevistos
                    mirim1={this.state.inscricaoMirim1QtdAlunosPrevistos}
                    mirim2={this.state.inscricaoMirim2QtdAlunosPrevistos}
                />
                <Grid container spacing={3} alignItems='center'>
                    <Grid item sm={12} lg={4}>
                        <Text
                            required
                            name="inscricaoMirim1QtdAlunosParticipantes"
                            label="Quantidade de Alunos que Fizeram a Prova de 1ª Fase no Mirim 1 (2º e 3º Anos)"
                            value={this.state.inscricaoMirim1QtdAlunosParticipantes}
                            onChange={this.handleChange}
                            error={this.state.errors.inscricaoMirim1QtdAlunosParticipantes}
                            helperText={this.state.helpers.inscricaoMirim1QtdAlunosParticipantes}
                            inputProps={{ maxLength: "4" }}
                            disabled={this.props.disabled ?? false}
                        />
                    </Grid>
                    <Grid item sm={12} lg={4}>
                        <Text
                            required
                            name="inscricaoMirim2QtdAlunosParticipantes"
                            label="Quantidade de Alunos que Fizeram a Prova de 1ª Fase no Mirim 2 (4º e 5º Anos)"
                            value={this.state.inscricaoMirim2QtdAlunosParticipantes}
                            onChange={this.handleChange}
                            error={this.state.errors.inscricaoMirim2QtdAlunosParticipantes}
                            helperText={this.state.helpers.inscricaoMirim2QtdAlunosParticipantes}
                            inputProps={{ maxLength: "4" }}
                            disabled={this.props.disabled ?? false}
                        />
                    </Grid>
                </Grid>
            </>
        )
    }
}

export default Fase1Participantes;