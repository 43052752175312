import React from 'react';

import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';

const useStyles = makeStyles((theme) => ({
    formControl: {
        margin: theme.spacing(2, 0),
        width: '100%',
    },
}));

export const Text = (props) => {
    const classes = useStyles();

    return (
        <FormControl className={classes.formControl}>
            <TextField
                {...props}
            />
        </FormControl>
    );
}

export default Text;