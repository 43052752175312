import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import { Layout } from '../../layouts/private/Private';

import Aluno from './Aluno';

export class List extends Component {
  render() {
    return (
      <>
        {this.props.withoutLayout ?
          <Aluno 
            school={this.props.school}
          />
        :
          <Layout>
            <Aluno />
          </Layout>
        }
      </>
    )
  }
}

export default withRouter(List);