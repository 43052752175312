import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';

import Text from '../../components/Inputs/Text/Text';

import Email from '../Email/Email';
import Telefone from '../Telefone/Telefone';

import {
    isBlank,
    isBlankHelperText,
    isCPFInvalid,
    isCPFHelperText,
    isNameCompoundHelperText,
    isNameCompoundInvalid,
} from '../../helper/ValidationHelper';
import { cpfMask } from '../../helper/MaskHelper';

export class ResponsavelInscricao extends Component {
    constructor(props) {
        super(props);
        props.parentRef(this);
        this.state = {
            nome: '',
            cargo: '',
            cpf: '',
            errors: {
                nome: false,
                cargo: false,
                cpf: false,
            },
            helpers: {
                nome: null,
                cargo: null,
                cpf: null,
            },
        }
        this.isValid = this.isValid.bind(this);
    }

    setEmail = e => this.Email = e;
    setTelefone = form => this.Telefone = form;

    // INPUTS
    handleChange = e => {
        this.setState({
            [e.target.name]: e.target.name === 'cpf' ? cpfMask(e.target.value) : e.target.value,
            errors: { ...this.state.errors, [e.target.name]: false },
            helpers: { ...this.state.helpers, [e.target.name]: null }
        })
    }

    // VALIDAÇÕES
    isValid = async () => {
        const formEmail = await this.Email.isValid();

        if ((isBlank(this.state.nome) || isNameCompoundInvalid(this.state.nome)) || isBlank(this.state.cpf) || isCPFInvalid(this.state.cpf) || isBlank(this.state.cargo) || !formEmail) {
            this.setState({
                errors: {
                    nome: isBlank(this.state.nome) || isNameCompoundInvalid(this.state.nome) ? true : false,
                    cpf: isBlank(this.state.cpf) || isCPFInvalid(this.state.cpf) ? true : false,
                    cargo: isBlank(this.state.cargo) ? true : false,
                },
                helpers: {
                    nome: isBlank(this.state.nome) ? isBlankHelperText() : isNameCompoundInvalid(this.state.nome) ? isNameCompoundHelperText() : null,
                    cpf: isBlank(this.state.cpf) ? isBlankHelperText() : isCPFInvalid(this.state.cpf) ? isCPFHelperText() : null,
                    cargo: isBlank(this.state.cargo) ? isBlankHelperText() : null,
                },
            });
            return false;
        }
        return true;
    }

    render() {
        return (
            <>
                <Grid container spacing={3} alignItems='center'>
                    <Grid item sm={12} lg={5}>
                        <Text
                            required
                            name="nome"
                            label="Nome do Responsável"
                            value={this.state.nome}
                            error={this.state.errors.nome}
                            onChange={this.handleChange}
                            helperText={this.state.helpers.nome}
                        />
                    </Grid>
                    <Grid item sm={12} lg={3}>
                        <Text
                            required
                            maxLength='14'
                            name="cpf"
                            label="CPF do Responsável"
                            value={this.state.cpf}
                            error={this.state.errors.cpf}
                            onChange={this.handleChange}
                            helperText={this.state.helpers.cpf}
                        />
                    </Grid>
                    <Grid item sm={12} lg={4}>
                        <Text
                            required
                            name="cargo"
                            label="Cargo (Função)"
                            value={this.state.cargo}
                            error={this.state.errors.cargo}
                            onChange={this.handleChange}
                            helperText={this.state.helpers.cargo}
                        />
                    </Grid>
                </Grid>

                <Email parentRef={this.setEmail} name="Email do Responsável" />
                <Telefone parentRef={this.setTelefone} />
            </>
        )
    }
}

export default ResponsavelInscricao;