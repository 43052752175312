import React from 'react'

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

const DadosEndereco = (props) => {
    return (
        <Paper elevation={3} style={{ padding: 24, marginTop: 24 }}>
            <Grid item sm={12} lg={12} style={{ marginBottom: 10 }}>
                <Typography variant="body2"><b>{props.name ?? `Endereço`}</b></Typography>
            </Grid>

            <Grid item sm={12} lg={12} >
                <Typography variant="body2">
                    CEP: <b>{props.endereco.cep}</b>
                </Typography>
                <Typography variant="body2">
                    {props.name ?? `Endereço`} : <b>{props.endereco.endereco}</b>
                </Typography>
                <Typography variant="body2">
                    Número: <b>{props.endereco.numero}</b> &nbsp;&nbsp;&nbsp; Complemento: <b>{props.endereco.complemento}</b>
                </Typography>
                <Typography variant="body2">
                    Bairro: <b>{props.endereco.bairro}</b>
                </Typography>
                <Typography variant="body2">
                    Município: <b>{props.endereco.municipio}</b> &nbsp;&nbsp;&nbsp; UF: <b>{props.endereco.uf}</b>
                </Typography>
            </Grid>
        </Paper>
    )
}

export default DadosEndereco;