import React from 'react';

import { makeStyles } from '@material-ui/core/styles';

import calendario from '../../assets/img/calendario.png';
import pdfCalendario from '../../assets/docs/calendario.pdf';

import { Download } from '../../components/Download/Download';
import { Container } from '@material-ui/core';

const useStyles = makeStyles(
    (theme) => (
        {
            calendario: {
                marginTop: theme.spacing(4),
                width: '80%',                
                ['@media (max-width:780px)']: { // eslint-disable-line no-useless-computed-key
                    width: '100%',
                },
            }
        }
    )
);

export const BannerCalendario = () => {
    const classes = useStyles();

    return (
        <Container maxWidth="lg" style={{ textAlign: 'center', marginTop: '46px' }}>
            <Download type="Calendário" file={pdfCalendario} />
            <picture>
                <img src={calendario} className={classes.calendario} alt={process.env.REACT_APP_NAME_GENERAL} />
            </picture>
        </Container>
    )
}

export default useStyles;