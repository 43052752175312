import React from "react";

import Alert from '@material-ui/lab/Alert';
import Typography from '@material-ui/core/Typography';

export const AlertaAlunosPrevistos = ({ mirim1, mirim2 }) => {
    return (
        <Alert severity="warning" style={{ marginTop: '10px' }}>
            <Typography variant="h6">
            </Typography>
            <Typography variant="body2">
                Previsão de Alunos Informada na Inscrição: <b>{mirim1}</b> para o <b>Mirim 1</b> e <b>{mirim2}</b> para o <b>Mirim 2</b>
            </Typography>
        </Alert>
    )
}

export default AlertaAlunosPrevistos;