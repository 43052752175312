import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';

export const EmailViewer = ({subject, message}) => {
    return (
        <>
            <Grid item sm={12} lg={12}>
                <Paper elevation={1} style={{ padding: 24 }} >
                    <Typography variant="h6">Assunto:</Typography>
                    <Typography variant="body2">{subject}</Typography>
                </Paper>
            </Grid>
            <Grid item sm={12} lg={12}>
                <Paper elevation={1} style={{ padding: 24 }} >
                    <Typography variant="h6">Mensagem:</Typography>              
                    <Typography variant="body2" dangerouslySetInnerHTML={{ __html: message }}></Typography>
                </Paper>
            </Grid>
        </>
    )
}

export default EmailViewer;