import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';

import Filters from '../../components/Filters/Filters';
import Text from '../../components/Inputs/Text/Text';
import SelectOption from '../../components/Inputs/Select/Select';

import { optionsType } from '../../variables/Enums/Email';

import { mecCodeMask } from '../../helper/MaskHelper';

import { removePagingAndSorting } from '../../helper/PaginationHelper';

export class EmailFilter extends Component {
    constructor(props) {
        super(props);
        props.parentRef(this);
        this.state = {
            filters: {
                inep: '',
                name: '',
                email: '',
                type: '',
            },
            count: 0,
        }
    }  

    // INPUTS
    handleChange = e => {
        this.setState({
            filters: {...this.state.filters, [e.target.name]: e.target.name === 'inep' ? mecCodeMask(e.target.value) : e.target.value }
        });
    };

    // APLICAR PERSISTENCIA
    handleFilterPersistence = (filters) => {
        filters = removePagingAndSorting(filters);

        this.setState({
            filters: {
                inep: filters.find(f =>  f.field === 'cdEscola') ? filters.find(f =>  f.field === 'cdEscola').value : "",
                name: filters.find(f =>  f.field === 'nmEscola') ? filters.find(f =>  f.field === 'nmEscola').value : "",
                email: filters.find(f =>  f.field === 'nmEmail') ? filters.find(f =>  f.field === 'nmEmail').value : "",
                type: filters.find(f =>  f.field === 'nmTipo') ? filters.find(f =>  f.field === 'nmTipo').value : "",
            },
            count: filters.reduce((total, f) => f.value !== '' ? total + 1 : total, 0),
        })
    }

    // FILTROS
    handleFilterApply = () => {
        let f = [];
        const { filters } = this.state;

        if (filters.inep) f.push({ field: 'cdEscola', value: filters.inep });
        if (filters.name) f.push({ field: 'nmEscola', value: filters.name });
        if (filters.email) f.push({ field: 'nmEmail', value: filters.email });
        if (filters.type) f.push({ field: 'nmTipo', value: filters.type });

        this.props.handleFilterChange(f);
    }

    // LIMPAR FILTROS
    handleFilterClear = () => {
        this.setState({
            filters: {
                inep: '',
                name: '',
                email: '',
                type: '',
            },
            count: 0,
        })
        this.props.handleFilterChange([], false);
    }

    // CONTAGEM DE FILTROS ATIVOS
    handleCountFilters = () => this.state.count;

    render () {
        return (
            <Filters
                handleFilter={this.handleFilterApply}
                handleFilterClear={this.handleFilterClear}
                countFilters={this.handleCountFilters}
            >
                <Grid item container spacing={3}>
                    <Grid item sm={12} lg={2}>
                        <Text
                            label='INEP da Escola'
                            value={this.state.filters.inep}
                            name='inep'
                            onChange={this.handleChange}
                        />
                    </Grid>
                    <Grid item sm={12} lg={3}>
                        <Text
                            label='Nome da Escola'
                            value={this.state.filters.name}
                            name='name'
                            onChange={this.handleChange}
                        />
                    </Grid>
                    <Grid item sm={12} lg={4}>
                        <Text
                            label='Email que Recebeu'
                            value={this.state.filters.email}
                            name='email'
                            onChange={this.handleChange}
                        />
                    </Grid>
                    <Grid item sm={12} lg={3}>
                        <SelectOption
                            label="Tipo de Email"
                            name='type'
                            value={this.state.filters.type}
                            onChange={this.handleChange}
                            options={optionsType}
                        />
                    </Grid>
                </Grid>
            </Filters>
        )
    }
}

export default EmailFilter;