import React from 'react';

import LinearProgress from '@material-ui/core/LinearProgress';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';

export default function ProgressBar(props) {
    return (
        <Box display="flex" alignItems="center">
            <Box>
                <Typography variant="body2"><b>Progresso da Tarefa:</b></Typography>
            </Box>
            <Box minWidth={35}>
                <Typography variant="body2">{`${Math.round(props.value)}%`}</Typography>
            </Box>
            <Box minWidth={35}>
                <CircularProgress size={14} />
            </Box>
            <Box width="100%" mr={1}>
                <LinearProgress style={{ height: '8px' }} variant="determinate" {...props} />
            </Box>
        </Box>
    );
}