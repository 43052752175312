import { GET_LIST_CONTEXT } from '../actions/Contexto';

const INITIAL_STATE = [];

export default function contexts(state = INITIAL_STATE, action) {
    switch (action.type) {
        case GET_LIST_CONTEXT:
            return [...action.contexts ]
        default:
            return state;
    }
}