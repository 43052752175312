import React, { Component } from 'react';

import Grid from '@material-ui/core/Grid';

import Filters from '../../components/Filters/Filters';
import Text from '../../components/Inputs/Text/Text';
import SelectOption from '../../components/Inputs/Select/Select';

import { optionsType } from '../../variables/Enums/Contexto';

import { removePagingAndSorting } from '../../helper/PaginationHelper';

export class ContextoFilter extends Component {
    constructor(props) {
        super(props);
        props.parentRef(this);
        this.state = {
            filters: {
                name: '',
                type: '',
            },
            count: 0,
        }
    }  

    // INPUTS
    handleChange = e => {
        this.setState({
            filters: {...this.state.filters, [e.target.name]: e.target.value }
        });
    };

    // APLICAR PERSISTENCIA
    handleFilterPersistence = (filters) => {
        filters = removePagingAndSorting(filters);

        this.setState({
            filters: {
                name: filters.find(f =>  f.field === 'nmVariavel') ? filters.find(f =>  f.field === 'nmVariavel').value : "",
                type: filters.find(f =>  f.field === 'nmTipo') ? filters.find(f =>  f.field === 'nmTipo').value : "",
            },
            count: filters.reduce((total, f) => f.value !== '' ? total + 1 : total, 0),
        })
    }

    // FILTROS
    handleFilterApply = () => {
        let f = [];
        const { filters } = this.state;

        if (filters.name) f.push({ field: 'nmVariavel', value: filters.name });
        if (filters.type) f.push({ field: 'nmTipo', value: filters.type });

        this.props.handleFilterChange(f);
    }

    // LIMPAR FILTROS
    handleFilterClear = () => {
        this.setState({
            filters: {
                name: '',
                type: ''
            },
            count: 0,
        })
        this.props.handleFilterChange([], false);
    }

    // CONTAGEM DE FILTROS ATIVOS
    handleCountFilters = () => this.state.count;

    render () {
        return (
            <Filters
                handleFilter={this.handleFilterApply}
                handleFilterClear={this.handleFilterClear}
                countFilters={this.handleCountFilters}
            >
                <Grid item container spacing={3}>
                    <Grid item sm={12} lg={3}>
                        <Text
                            label='Nome da Variável'
                            value={this.state.filters.name}
                            name='name'
                            onChange={this.handleChange}
                        />
                    </Grid>
                    <Grid item sm={12} lg={3}>
                        <SelectOption
                            label="Tipo de Variável"
                            name='type'
                            value={this.state.filters.type}
                            onChange={this.handleChange}
                            options={optionsType}
                        />
                    </Grid>
                </Grid>
            </Filters>
        )
    }
}

export default ContextoFilter;