import React, { useContext } from 'react';
import { useKeycloak } from '@react-keycloak/web';

import Dashboard from "../../components/Dashboard/Dashboard";
import Main from "../../components/Container/Container";
import Regua from '../../components/Regua/Regua';

import { MenuContext } from '../../context/MenuContext';

const LayoutPrivate = (props) => {
  const { open, setOpen } = useContext(MenuContext);

  const handleDrawerOpen = () => setOpen(true);
  const handleDrawerClose = () => setOpen(false);

  return (
    <>
      <Dashboard
        open={open}
        handleDrawerOpen={handleDrawerOpen}
        handleDrawerClose={handleDrawerClose}
        handleLogout={props.handleLogout}
      />
      <Main open={open} >
        {props.children}
      </Main>
      <Regua open={open} />
    </>
  )
}

export const Layout = (props) => {
  const [keycloak, ] = useKeycloak();

  const handleLogout = () => keycloak.logout({ redirectUri: `${process.env.REACT_APP_PUBLIC_URL}` });

  return (
    <LayoutPrivate
      handleLogout={handleLogout}
    >
      {props.children}
    </LayoutPrivate>
  )
}

export default LayoutPrivate;