export const optionsExistsPhone = [
    { label: 'Não', value: false },
    { label: 'Sim', value: true }
]

export const optionsPhone = [
    { value: 'RESIDENCIAL', label: 'Residencial' },
    { value: 'CELULAR', label: 'Celular' },
    { value: 'COMERCIAL', label: 'Comercial' },
];

export const patternPhones = phones => {
    let array = [];

    phones.forEach(phone =>
        array.push([
            phone.nmDdd,
            phone.nmFone,
            phone.nmRamalFone,
            phone.nmTipo,
        ])
    );

    return array;
}

export const phoneFormatter = phone => {
    return phone.nmRamal ? `(${phone.nmDdd}) ${phone.nmFone} - Ramal: ${phone.nmRamal} ` : `(${phone.nmDdd}) ${phone.nmFone} `
}