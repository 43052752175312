import React from 'react';

import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import ProgressBar from '../../components/ProgressBar/ProgressBar';
import { parseDateTime, toDateTime } from '../../helper/DateHelper';

const BarraProgressoTarefa = ({ inicioProcessamento, paraProcessar, processados }) => {
    const tempoDecorrido = new Date() - parseDateTime(inicioProcessamento);
    const tempoMedioProcessamento = tempoDecorrido * (paraProcessar - processados) / processados;

    const porcentagem = () => processados ? processados * 100 / paraProcessar : 0;
    const tempoRestanteEstimado = () => inicioProcessamento ? calcularDiferenca(new Date(new Date().getTime() + (tempoMedioProcessamento)).getTime() - new Date().getTime()) : "Calculando...";
    const dataHoraEstimado = () => inicioProcessamento ? toDateTime(new Date().getTime() + tempoMedioProcessamento) : "Calculando...";

    const calcularDiferenca = (diferenca) => {
        const dias = Math.floor(diferenca / 1000 / 60 / 60 / 24);
        diferenca -= dias * 1000 * 60 * 60 * 24;

        const horas = Math.floor(diferenca / 1000 / 60 / 60);
        diferenca -= horas * 1000 * 60 * 60;

        const minutos = Math.floor(diferenca / 1000 / 60);
        diferenca -= minutos * 1000 * 60;

        const segundos = Math.floor(diferenca / 1000);

        return (dias > 0 ? dias + " dias, " : "") + (horas > 0 ? horas + " horas, " : "") + (minutos > 0 ? minutos + " minutos e " : "") + segundos + " segundos.";
    }

    return (
        <>
            <Grid container spacing={2} style={{ marginTop: '12px' }} >
                <Grid item sm={12} lg={5}>
                    <ProgressBar value={porcentagem(processados, paraProcessar)} />
                </Grid>
            </Grid>            
            <Grid container spacing={2} style={{ marginTop: '12px' }} >
                <Grid item sm={12} lg={4}>
                    <Typography variant="body2"><b>Tempo Restante Estimado para Término:</b> {tempoRestanteEstimado()}</Typography>
                </Grid>
                <Grid item sm={12} lg={3}>
                    <Typography variant="body2"><b>Data e Horário Estimados para Término:</b> {dataHoraEstimado()}</Typography>
                </Grid>
            </Grid>
        </>
    )
}

export default BarraProgressoTarefa;