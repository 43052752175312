import React from 'react'

import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

const DadosResponsavel = (props) => {
    return (
        <Paper elevation={3} style={{ padding: 24, marginTop: 24 }}>
            <Grid item sm={12} lg={12} style={{ marginBottom: 10 }}>
                <Typography variant="body2"><b>Dados do Responsável Pela Inscrição</b></Typography>
            </Grid>

            <Grid item sm={12} lg={12} >
                <Typography variant="body2">
                    Nome da Responsável: <b>{props.responsavel.nome}</b>
                </Typography>
                <Typography variant="body2">
                    CPF: <b>{props.responsavel.cpf}</b>
                </Typography>
                <Typography variant="body2">
                    Cargo: <b>{props.responsavel.cargo}</b>
                </Typography>
                <Typography variant="body2">
                    Email: <b>{props.responsavel.email}</b>
                </Typography>
                <Typography variant="body2">
                    Telefones: <b>{props.responsavel.telefones ? props.responsavel.telefones : "Não Informado"}</b>
                </Typography>
            </Grid>
        </Paper>
    )
}

export default DadosResponsavel;