import React from 'react';

import Header from '../../components/Header/Header';

import { BannerCalendario } from './Style';

import Layout from '../../layouts/public/Public';

const Calendario = () => {
    return (
        <Layout>
            <Header />
            <BannerCalendario />
        </Layout>
    )
}

export default Calendario;